import React from "react"
import { graphql } from "gatsby"
import {Badge} from "react-bootstrap";
import ReactHtmlParser from 'react-html-parser';

import SEO from "../../utils/seo"
import Layout from "../../layout/layout"
import ContentContainer from "../../components/ContentContainer"

import WorkHistory from "../../../content/resume/work-history";
import EducationHistory from "../../../content/resume/education-history";
import SkillsList from "../../../content/resume/skill-list";

function ResumePage({data}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>      
      <SEO title={frontmatter.title} />

      <ContentContainer heading={frontmatter.title} headline={frontmatter.headline}>
        
        <div className="px-md-0">

        <section id="work-history" className="mt-5">
          <h2 className="pb-4 mb-3">{frontmatter.roles_heading}</h2>
          {WorkHistory.map(job => (          
            <div key={job.title} className="resume-item pb-4">
              <h3 className="h4">{job.title}</h3>
              <div className="text-body display pb-2">
                <span className="h5">{job.organisation}, {job.location}</span>
                <span className="h5 d-block float-sm-right">{job.dates}</span>
              </div>               
              <div className="description text-muted">
                <p>{job.description}</p>
              </div>            
            </div>                              
          ))} 
        </section>

        <section id="skill-list" className="mt-3">
          <h2 className="pb-4 mb-3">{frontmatter.skills_heading}</h2>
          <div className="resume-item pb-4">  
            {SkillsList.map(skill => (          
              <Badge key={skill.name} className="mr-2 mb-2 p-2 badge-large" variant="secondary">
                {skill.name}
              </Badge>                     
            ))}
          </div> 
        </section>
      
        <section id="education" className="mt-3">
          <h2 className="pb-4 mb-3">{frontmatter.education_heading}</h2>
          {EducationHistory.map(course => (
            <div key={course.institue} className="resume-item pb-4">            
              <h3 className="h4 text-dark">{course.institue}</h3>
              <div className="description text-muted">
                <p>{course.qualification}</p>
              </div>
            </div>
          ))} 
        </section>
       
        </div>

        <section id="additional-content" className="mt-3">
          {ReactHtmlParser(html)}
        </section>
      </ContentContainer>
    </Layout>
  )
}

export default ResumePage;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {        
        path
        title 
        heading 
        headline 
        roles_heading   
        skills_heading
        education_heading  
      }
    }
  }
`