export default [
  {
    name: 'HTML',
    icon: 'html5',
    color: '',      
  },
  {
    name: 'CSS',
    icon: 'css3 alternate',
    color: '',      
  },
  {
    name: 'JavaScript',
    icon: 'js square',
    color: '',      
  },
  {
    name: 'React',
    icon: 'react',
    color: '',      
  },
  {
    name: 'Git',
    icon: 'code branch',
    color: '',      
  },
  {
    name: 'PHP',
    icon: 'php',
    color: '',      
  },
  {
    name: 'WordPress',
    icon: 'wordpress',
    color: '',      
  },
  {
    name: 'Linux',
    icon: 'linux',
    color: '',      
  },
  {
    name: 'NPM',
    icon: 'npm',
    color: '',
  },
  {
    name: 'AWS',
    icon: 'aws',
    color: '',      
  },
  /*{
    name: 'Node JS',
    icon: 'node js',
    color: '',      
  },*/
  {
    name: 'Nginx',
    icon: 'mc-icon-nginx',
    color: '',      
  },
  {
    name: 'Ruby on Rails',
    icon: 'mc-icon-rails',
    color: '',      
  },
  {
    name: 'JQuery',
    icon: 'mc-icon-jquery',
    color: '',      
  },
  {
    name: 'Apache',
    icon: 'mc-icon-apache',
    color: '',      
  }
]