export default [  
  {
    title: 'Freelance Web Developer',
    organisation: 'Self-Employed',
    location: 'Remote',
    dates: '2000 - Present',
    tags: ['WordPress', 'PHP', 'Rails', 'JavaScript', 'React', 'HTML', "DevOps", "Nginx", "Apache"],
    description:
      'I‘ve been building websites and applications for almost 20 years now. I‘ve worked with a variety of languages and frameworks over the years far too many to list here, these days I prefer to work with PHP, Ruby on Rails & JavaScript/ES2015+ (including Angular, React, Lodash, Node JS).',    
  },
  {
    title: 'Support Lead, APAC',
    organisation: 'Kinsta',
    location: 'Remote',
    dates: '2020 - Present',
    tags: ['WordPress', 'PHP', 'Ubuntu', 'Nginx', 'Reverse Proxies', 'MariaDB', 'Elastic Search', 'SSL'],
    // TODO: Update description for Kinsta Support Lead role
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
  },
  {
    title: 'WordPress & Linux Support Engineer',
    organisation: 'Kinsta',
    location: 'Remote',
    dates: '2019 - 2020',
    tags: ['WordPress', 'PHP', 'Ubuntu', 'Nginx', 'Reverse Proxies', 'MariaDB', 'Elastic Search', 'SSL'],
    description:
      'My day to day tasks include adjusting NGINX configuration files, editing PHP settings, configuring reverse proxies, installing Redis & Elastic Search, reviewing logs for PHP and server errors, installing SSL certificates, removing malware & troubleshooting a wide variety of performance & config issues. The role requires a deep understanding of Linux, NGINX, MySQL/MariaDB, HTML, CSS, JavaScript, PHP, DNS, CDN, and object & page caching.'
  },
  {
    title: 'Technical & Operations Director',
    organisation: 'Otilas IT',
    location: 'Bella Vista',
    dates: '2009 - 2019',
    tags: ["Management", "Ruby on Rails", "DevOps", "CRM", "AWS", "eCommerce", "PHP", "JavaScript"],
    description:
      'Otilas was an IT Managed Services company I ran with my husband for 10 years. We managed a team of 9 employees both technical and administrative. I managed the technical support team who were responsponsible for ensuring smooth day to day operations of customer infrastructure. I main- tained most of the Linux servers as the majority of the team were more comfortable with Windows environment.      I also worked on a variety of projects including infrastructure deployments and development projects. I worked on a large AWS infrastructure deployment for a client in Singapore which consisted of around 30 servers and implementation of AWS WorkSpace for fully managed cloud desktops.'
  },
  {
    title: 'Lead Developer, Solution Architect',
    organisation: 'Ellaslist',
    location: 'Remote',
    dates: '2016 - 2017',
    tags: ["Ruby on Rails", "JavaScript", "HTML", "CSS", "DevOps", "Nginx", "Project Management"],
    description:
      'Ellaslist is a Hyper Local Around-You digital media platform aimed solely at Australian Families - connecting them to the best things in their neighbourhood. I was engaged to rebuild this from the ground up migrating data from a poorly cobbled together WordPress site into a bespoke Ruby on Rails application providing them with a stable platform for the future.'
  },
  {
    title: 'Lead Frontend Developer',
    organisation: 'RedAnt',
    location: 'Sydney',
    dates: '2007 - 2013',
    tags: ["Ruby on Rails", "HTML", "CSS", "PrototypeJS", "Angular", "JQuery", "Nginx"],
    description:
      'I was the first front-end specialist hired at Red Ant bridging the gap between the design team and development team. I mostly developed websites with Ruby on Rails, HTML(ERB), CSS(SASS/SCSS) & JavaScript (Prototype JS, JQuery, Backbone JS, Underscore JS, & Angular) Some of the most notable clients I worked on include; Huggies, City of Sydney, Yates & 12wbt.'
  },
  {
    title: 'Senior Web Developer/Designer',
    organisation: 'iSell (ITQuoter)',
    location: 'Sydney',
    dates: '2005 - 2007',
    tags: ["Java", "JSP", "JSTL", "Tomcat", "HTML", "CSS", "PrototypeJS", "eCommerce"],
    description:
      'My role involved designing templates and implementing eCommerce websites using JSP, CSS, xHTML, and Javascript. Implementing payment gateways, setting up and deploying the Java web applications to apache tomcat environments.'
  }
];