export default [
    {
      institue: 'CHARLES STURT UNIVERSITY',
      qualification: 'B.InfoTech, Online Systems & Software Engineering',
      dates: '2007 - 2012',      
    },
    {
      institue: 'WESTERN SYDNEY UNIVERSITY',
      qualification: 'Information Technology',
      dates: '2003 - 2004',
    },
    {
      institue: 'TAFE NSW',
      qualification: 'Certificate IV. Information Technology Network Management',
      dates: '2000 - 2001'
    }
  ];